import css from './who-is-lensa.module.scss';

const WhoIsLensa: React.FC = () => (
  <section className={css['content']}>
    <div className={css['hero']}>
      <div className={css['image']} />
      <div className={css['caption']}>
        <p className={css['title']}>Who is Lensa?</p>
        <img src='/employer/assets/images/svg/lensa_logo_white.svg' className={css['logo']} alt='Lensa logo' />
      </div>
    </div>
    <div className={css['description']}>
      <div className={css['description-content']}>
        <h6 className={css['title']}>Who is Lensa?</h6>
        <img src='/employer/assets/images/svg/lensa_logo_blue.svg' alt='Lensa logo' className={css['logo']} />
        <p className={css['text']}>
          We are a career platform that empowers job seekers to make smarter decisions through job search and career
          pathing tools, knowledge, and professional resources.
        </p>
      </div>
    </div>
  </section>
);

export default WhoIsLensa;
