export const icons = {
  rocket: (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='m17.1 12.123-5.4 4.32a1 1 0 0 1-1.332-.074l-2.735-2.736a1 1 0 0 1-.074-1.332l4.32-5.399a10.407 10.407 0 0 1 8.126-3.906v0a1 1 0 0 1 1 1v0c0 3.162-1.437 6.152-3.905 8.127Z'
        clipRule='evenodd'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M16.002 13.001v4.384a1 1 0 0 1-.553.895l-2.417 1.208a1 1 0 0 1-1.396-.578L11 17.003M6.997 13l-1.907-.636a1 1 0 0 1-.578-1.396L5.72 8.551a1 1 0 0 1 .895-.553H11'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='m5.747 20.59-2.75.414.412-2.751a2.055 2.055 0 0 1 1.728-1.727v0a2.055 2.055 0 0 1 2.338 2.338v0a2.055 2.055 0 0 1-1.728 1.727v0Z'
        clipRule='evenodd'
      />
    </svg>
  ),
};
