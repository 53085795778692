import classNames from 'classnames';
import { FC } from 'react';

import css from './style.module.scss';

type TextInputProps = {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
};

const TextInput: FC<TextInputProps> = ({ value, setValue, placeholder = '', className, disabled = false }) => (
  <input
    type='text'
    className={classNames(css['input'], className)}
    value={value}
    onChange={(e) => setValue(e.target.value)}
    placeholder={placeholder}
    disabled={disabled}
  />
);

export default TextInput;
