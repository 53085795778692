import type { QueryParam } from './QueryParam';

export default class UrlBuilder {
  baseUrl: string = '';

  path: string = '/';

  queryParams: QueryParam[] = [];

  setBaseUrl = (baseUrl: string) => {
    this.baseUrl = baseUrl;
    return this;
  };

  setPath = (path: string) => {
    this.path = path;
    return this;
  };

  setQueryParam = (queryParam: QueryParam): UrlBuilder => {
    this.queryParams.push(queryParam);
    return this;
  };

  setQueryParams = (queryParams: QueryParam[]): UrlBuilder => {
    this.queryParams.push(...queryParams);
    return this;
  };

  build = (): string => {
    const parameters = this.queryParams.length
      ? '?' + this.queryParams.map(({ name, value }) => `${name}=${value}`).join('&')
      : '';

    return this.baseUrl + this.path + parameters;
  };
}
