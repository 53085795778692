import Button from '@/components/Button';
import Tooltip from '@/components/tooltip';
import CardBase from '@/components/card';
import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import css from './card.module.scss';

export enum CheckoutOptions {
  INVOICE = 'invoice',
  PAYPAL = 'paypal',
  CREDIT_CARD = 'credit_card',
}

const displayedCheckoutOptions: { [key in CheckoutOptions]: string } = {
  [CheckoutOptions.INVOICE]: 'Invoice',
  [CheckoutOptions.PAYPAL]: 'PayPal',
  [CheckoutOptions.CREDIT_CARD]: 'Credit card',
};

export enum CardVariant {
  DEFAULT = 'default',
  FEATURED = 'featured',
}

type CardProps = {
  variant: CardVariant;
  name: string;
  shortDescription: string;
  iconSrc: string;
  recommendedFor: string;
  options: {
    text: string;
    tooltip?: string;
  }[];
  paymentType: string;
  checkoutOptions: CheckoutOptions[];
  budgetType: ReactElement;
  contact: string;
  button: {
    text: string;
    href: string;
  };
};

const Card: FC<CardProps> = ({
  variant = CardVariant.DEFAULT,
  name,
  shortDescription,
  iconSrc,
  recommendedFor,
  options,
  paymentType,
  checkoutOptions,
  budgetType,
  contact,
  button,
}) => (
  <CardBase className={classNames(css['content'], css[`content-${variant}`])}>
    <p className={css['brand']}>FastTalent</p>
    <p className={classNames(css['name'], css[`name-${variant}`])}>{name}</p>
    <div className={classNames(css['separator'], css['separator-first'])} />
    <img src={iconSrc} alt='card icon' className={css['icon']} />
    <p className={classNames(css['recommended-for'], css[`recommended-for-${variant}`])}>{recommendedFor}</p>
    <p className={css['short-description']}>{shortDescription}</p>
    <div className={classNames(css['separator'], css['separator-second'])} />
    <div className={css['options']}>
      {options.map((option) => (
        <div className={css['option']} key={option.text}>
          <img src='/employer/assets/images/svg/green_check_icon.svg' alt='green check icon' />
          <p className={css['text']}>{option.text}</p>
          {option.tooltip && <Tooltip text={option.tooltip} className={css['tooltip']} />}
        </div>
      ))}
    </div>
    <div className={classNames(css['payment-type'], css[`payment-type-${variant}`])}>
      <p>{paymentType}</p>
    </div>
    <div className={css['checkout-options']}>
      <div className={css['checkout-option-icons']}>
        {checkoutOptions.map((checkoutOption) => (
          <img
            width='32'
            height='33'
            key={checkoutOption}
            src={`/employer/assets/images/svg/checkout_option_${checkoutOption}_icon.svg`}
            alt={`${checkoutOption} icon`}
          />
        ))}
      </div>
      <p>{`*${checkoutOptions.map((checkoutOption) => displayedCheckoutOptions[checkoutOption]).join(', ')}`}</p>
    </div>
    <div className={classNames(css['budget-type'], css[`budget-type-${variant}`])}>{budgetType}</div>
    <p className={css['contact']}>{contact}</p>
    <div className={css['button-container']}>
      <a href={button.href} target='_blank' className={css['link']}>
        <Button
          title={button.text}
          variant={`pricing${CardVariant.FEATURED === variant ? '-featured' : ''}`}
          className={css['button']}
        />
      </a>
    </div>
  </CardBase>
);

export default Card;
