import css from './footer.module.scss';

const Footer: React.FC = () => (
  <section className={css['content']}>
    <div className={css['wrap']}>
      <div className={css['links']}>
        <p className={css['link']}>
          <a href='https://lensa.com/affiliate-program' target='_blank' rel='noopener'>
            Affiliate Program
          </a>
        </p>
        <p className={css['link']}>
          <a href='https://lensa.com/terms-and-conditions' target='_blank' rel='noopener'>
            Terms and Conditions
          </a>
        </p>
        <p className={css['link']}>
          <a href='https://lensa.com/talent-jobseekers-agreement' target='_blank' rel='noopener'>
            Jobseeker&apos;s Agreement
          </a>
        </p>
        <p className={css['link']}>
          <a href='https://lensa.com/privacy-policy' target='_blank' rel='noopener'>
            Privacy Policy
          </a>
        </p>
        <p className={css['link']}>
          <a href='https://lensa.com/community-guideline' target='_blank' rel='noopener'>
            Community Guidelines
          </a>
        </p>
        <p className={css['link']}>
          <a href='https://lensa.com/faq' target='_blank' rel='noopener'>
            FAQ
          </a>
        </p>
        <p className={css['break']} />
        <p className={css['link--whitebg']}>
          <a
            onClick={() => {
              (window as any).AZCookieConsent.onOpenPreference();
            }}
          >
            Your Privacy Choices
            <span className={css['icon-right']}>
              <img src='/employer/assets/images/svg/privacy_choices.svg' alt='Arrow right' />
            </span>
          </a>
        </p>

        {/* <p className={css['link']}>
          <a href='https://lensa.com/privacy-policy#notice-of-personal-info-collected' target='_blank' rel='noopener'>
            Notice of personal information collected
          </a>
        </p> */}
        {/* <p className={css['link']}>
          <a href='https://lensa.com/privacy-policy#do-not-sell-my-personal-information' target='_blank' rel='noopener'>
            Do not sell my personal information
          </a>
        </p> */}
      </div>
      <div className={css['socials']}>
        <div className={css['icon']}>
          <a href='https://www.facebook.com/Lensa/' target='_blank' rel='noopener'>
            <img src='/employer/assets/images/svg/facebook_icon32.svg' alt='Facebook logo' />
          </a>
        </div>
        <div className={css['icon']}>
          <a href='https://twitter.com/lensainc' target='_blank' rel='noopener'>
            <img src='/employer/assets/images/svg/twitter_icon32.svg' alt='Twitter logo' />
          </a>
        </div>
        <div className={css['icon']}>
          <a href='https://www.linkedin.com/company/lensa/' target='_blank' rel='noopener'>
            <img src='/employer/assets/images/svg/linkedin_icon32.svg' alt='LinkedIn logo' />
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default Footer;
