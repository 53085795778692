import classNames from 'classnames';
import Button from '@/components/Button';

import css from './contact-us.module.scss';

const ContactUs: React.FC = () => {
  const url = 'https://contact.fasttalent.com/?utm_content=user_selector_contactus';
  return (
    <section className={css['content']}>
      <p className={css['title']}>Ready?</p>
      <p className={css['subtitle']}>
        Start finding talent <br className={css['break']} />
        in just 2-3 business days.
      </p>
      <a className={classNames(css['button'], css['button--mobile'])} href={url} target='_blank'>
        <Button title='Contact Us' variant='contentM' icon='rocket' />
      </a>
      <a className={classNames(css['button'], css['button--not-mobile'])} href={url} target='_blank'>
        <Button title='Contact Us' variant='contentL' icon='rocket' />
      </a>
    </section>
  );
};

export default ContactUs;
