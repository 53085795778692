import css from './posting-jobs.module.scss';

const PostingJobs: React.FC = () => (
  <section className={css['content']}>
    <div className={css['intro']}>
      <h2>
        <span>Posting jobs</span> to Lensa
        <br /> is easy and effortless!
      </h2>
      <div className={css['line']} />
      <p>
        Lensa has several options to make posting <br />
        jobs to our site easy and effortless. We will <br />
        update your jobs on Lensa up to 2 times / hour <br />
        to ensure freshness.
      </p>
    </div>
    <div className={css['options']}>
      <div className={css['option']}>
        <img src='/employer/assets/images/svg/jobs_icon.svg' alt='Job wrap icon svg' />
        <h6>
          Job wrap from your
          <br />
          company career page
        </h6>
        <p>
          Automatically wrap your jobs
          <br />
          from your company career
          <br />
          site at no cost
        </p>
      </div>
      <div className={css['option']}>
        <img src='/employer/assets/images/svg/xml_icon.svg' alt='Job wrap icon svg' />
        <h6>
          XML or CSV file
          <br />
          of your jobs
        </h6>
        <p>
          Parse and post jobs from
          <br />
          any source including XML and
          <br />
          CSV files or your ATS
        </p>
      </div>
      <div className={css['option']}>
        <img src='/employer/assets/images/svg/ftp_icon.svg' alt='Job wrap icon svg' />
        <h6>
          FTP site or third-party
          <br />
          recruiting platforms
        </h6>
        <p>
          Feeds from programmatic
          <br />
          platforms like Recruitics,
          <br />
          Appcast, and others
        </p>
      </div>
    </div>
  </section>
);

export default PostingJobs;
