import { useEffect, useState } from 'react';
import { QueryParam } from '../QueryParam';

const useQueryParamsFromCurrentUrl = (filter: (queryParam: QueryParam) => boolean = () => true) => {
  const [utmQueryParameters, setUtmQueryParameters] = useState<QueryParam[]>([]);

  useEffect(() => {
    setUtmQueryParameters(
      typeof window !== 'undefined'
        ? window.location.search
            .substring(1)
            .split('&')
            .map((queryParam) => {
              const [name, value] = queryParam.split('=');
              return { name, value };
            })
            .filter(filter)
        : []
    );
  }, [filter]);

  return utmQueryParameters;
};

export default useQueryParamsFromCurrentUrl;
