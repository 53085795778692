import Button from '@/components/Button';
import Card from '@/components/card';
import TextInput from '@/components/textInput';
import classNames from 'classnames';
import { AnalyticsService } from 'modules/analytics/analyticsService';
import { InvalidEmailFormatError } from 'modules/subscription-form/domain/Errors';
import MockedSubscriptionAPI from 'modules/subscription-form/mocked/MockedSubscriptionAPI';
import RemoteSubscriptionAPI from 'modules/subscription-form/remote/RemoteSubscriptionAPI';
import { FC, useState } from 'react';

import css from './subscriptionForm.module.scss';

type SubscriptionState = {
  displayedError: string | null;
  successful: boolean;
  inProgress: boolean;
};

const SubscriptionForm: FC = () => {
  const [emailInputValue, setEmailInputValue] = useState('');

  const [subscriptionState, setSubscriptionState] = useState<SubscriptionState>({
    displayedError: null,
    successful: false,
    inProgress: false,
  });

  const handleGetEBookButtonClick = async () => {
    if (subscriptionState.inProgress) {
      return;
    }

    setSubscriptionState({ ...subscriptionState, inProgress: true, displayedError: null });
    try {
      const SubscriptionAPI =
        process.env.NEXT_PUBLIC_ENV === 'production' ? RemoteSubscriptionAPI : MockedSubscriptionAPI;

      await new SubscriptionAPI(new AnalyticsService()).subscribe(emailInputValue);
      setSubscriptionState({ ...subscriptionState, successful: true, inProgress: false });
    } catch (error) {
      if (error instanceof InvalidEmailFormatError) {
        setSubscriptionState({
          ...subscriptionState,
          displayedError:
            'The provided e-mail address does not seem to be valid. Please try again with a valid e-mail address.',
          inProgress: false,
        });
      } else {
        setSubscriptionState({
          ...subscriptionState,
          displayedError: 'Sorry, something went wrong. Please try signing up again in a few minutes.',
          inProgress: false,
        });
      }
    }
  };

  return (
    <section id='ebook'>
      <Card className={css['card']}>
        <div className={css['banner']}>
          <img src='/employer/assets/images/svg/rectangle_banner.svg' alt='rectangle banner' />
          <img src='/employer/assets/images/svg/special_offer_text.svg' alt='rectangle banner' />
        </div>

        <div className={css['content']}>
          <div className={css['ebook-cover']}>
            <img src='/employer/assets/images/webp/ebook_cover_mobile.webp' alt='ebook cover mobile' className='mobile-only' />
            <img src='/employer/assets/images/webp/ebook_cover_desktop.webp' alt='ebook cover tablet' className='tablet-only' />
            <img
              src='/employer/assets/images/webp/ebook_cover_desktop.webp'
              alt='ebook cover desktop'
              className='desktop-only'
            />
          </div>

          <div className={css['ebook-information']}>
            <p className={css['title']}>
              How To Find The{' '}
              <span className={css['gradient-word']}>
                <span className={classNames(css['underline'], 'desktop-only')} />
                best
              </span>{' '}
              <span className={css['gradient-word']}>
                <span className={classNames(css['underline'], 'mobile-only')} />
                <span className={classNames(css['underline'], 'tablet-only')} />
                Talent?
              </span>
            </p>
            <p className={css['description']}>
              <span className={css['bold']}>Sign up now and get our free eBook</span> for employers &apos;
              <span className={css['highlighted']}>Hiring and Workplace Trends 2023</span>&apos; + our{' '}
              <span className={css['bold']}>interactive dashboard</span> showing workforce distribution, job posting
              trends, and an employer overview based on data from 20M+ active users and 60M job postings.
            </p>
            <TextInput
              value={emailInputValue}
              setValue={(value) => {
                setEmailInputValue(value);
                setSubscriptionState({ ...subscriptionState, displayedError: null });
              }}
              placeholder='Your email'
              className={css['input']}
              disabled={subscriptionState.successful}
            />
            {subscriptionState.displayedError && (
              <div className={css['unsuccessful-subscription-label']}>
                <p>{subscriptionState.displayedError}</p>
              </div>
            )}
            {!subscriptionState.successful && !subscriptionState.displayedError && (
              <Button
                title='Get eBook'
                variant='contentL-featured'
                className={css['button']}
                onClick={handleGetEBookButtonClick}
              />
            )}
            {subscriptionState.successful && (
              <div className={css['successful-subscription-label']}>
                <p>
                  <span>Thanks for sharing your email!</span> Look out for an email with your free eBook and access to
                  our dashboards soon!
                </p>
              </div>
            )}
            {!subscriptionState.successful && (
              <p className={css['disclaimer']}>
                By submitting this form you agree to Lensa’s Terms and have read the Privacy Policy.
                <br />
                Unsubscribe any time
              </p>
            )}
          </div>
        </div>
      </Card>
      <div className={css['separator']} />
    </section>
  );
};

export default SubscriptionForm;
