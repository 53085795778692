import AnalyticsService from 'modules/analytics/analyticsService/domain/AnalyticsService';
import { AnalyticsEventBuilder } from 'modules/analytics/analyticsService/domain/AnalyticsEvent';
import SubscriptionAPI from '../domain/SubscriptionAPI';
import { InvalidEmailFormatError } from '../domain/Errors';

export default class RemoteSubscriptionAPI extends SubscriptionAPI {
  constructor(private readonly analyticsService: AnalyticsService) {
    super();
  }

  subscribe = async (email: string) => {
    if (!this.isValidEmailAddress(email)) {
      this.analyticsService.send(new AnalyticsEventBuilder().setName('DownloadeBook').setLabel('Unsuccessful').build());
      throw new InvalidEmailFormatError();
    }

    const subscriptionResponse = await fetch('https://contact.fasttalent.com/feccollector.php', {
      method: 'POST',
      headers: { 'Content-Type': 'text/plain' },
      body: JSON.stringify({
        emailAddress: email,
        source: 'lensa.com/employer',
      }),
    });

    if (!subscriptionResponse.ok) {
      this.analyticsService.send(new AnalyticsEventBuilder().setName('DownloadeBook').setLabel('Unsuccessful').build());
      throw new Error();
    }

    this.analyticsService.send(new AnalyticsEventBuilder().setName('DownloadeBook').setLabel('Successful').build());
    await subscriptionResponse.text();
  };
}
