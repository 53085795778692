import { useUTMQueryParamsFromCurrentUrl, UrlBuilder } from 'modules/url';
import { useEffect, useMemo, useRef } from 'react';
import Card, { CardVariant, CheckoutOptions } from '../card';
import css from './card-list.module.scss';

const CardList: React.FC = () => {
  const cardListRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (cardListRef.current) {
      cardListRef.current.scrollLeft = cardListRef.current.scrollWidth / 2 - window.innerWidth / 2;
    }
  }, []);

  const utmQueryParamsFromUrl = useUTMQueryParamsFromCurrentUrl();

  const selfServeButtonUrl = useMemo(
    () =>
      new UrlBuilder()
        .setBaseUrl(process.env.NEXT_PUBLIC_DIRECTEMPLOYER_SITE_PATH || '')
        .setPath('/sign-up/')
        .setQueryParams(utmQueryParamsFromUrl)
        .setQueryParam({ name: 'utm_content', value: 'user_selector_selfserve' })
        .build(),
    [utmQueryParamsFromUrl]
  );

  const bulkPostButtonUrl = useMemo(
    () =>
      new UrlBuilder()
        .setBaseUrl('https://contact.fasttalent.com')
        .setQueryParams(utmQueryParamsFromUrl)
        .setQueryParam({ name: 'utm_content', value: 'user_selector_bulkpost' })
        .build(),
    [utmQueryParamsFromUrl]
  );

  const performanceButtonUrl = useMemo(
    () =>
      new UrlBuilder()
        .setBaseUrl('https://contact.fasttalent.com')
        .setQueryParams(utmQueryParamsFromUrl)
        .setQueryParam({ name: 'utm_content', value: 'user_selector_performance' })
        .build(),
    [utmQueryParamsFromUrl]
  );

  return (
    <section className={css['content']} ref={cardListRef}>
      <Card
        key='selfServe'
        variant={CardVariant.DEFAULT}
        name='SelfServe'
        shortDescription='Allows you to post & purchase single job postings when you need them'
        iconSrc='/employer/assets/images/svg/self_serve_icon.svg'
        recommendedFor='Best for single job postings'
        options={[
          { text: '40+ niche & general job board partners' },
          {
            text: 'Self service job posting',
            tooltip:
              'You are in control!  Post your own jobs and pay via credit card within your FastTalent Self Serve account.',
          },
          { text: 'Inbuilt ATS' },
          { text: 'Get applications by email' },
        ]}
        paymentType='Pay per job posting'
        checkoutOptions={[CheckoutOptions.CREDIT_CARD]}
        budgetType={
          <p className={css['discount-budget-type']}>
            <img className={css['discount-sticker']} src='/employer/assets/images/svg/discount_sticker.svg' alt='60% off' />
            <s>$250</s> <b>$99/job</b> <br className='mobile-only' /> post for 30 days
          </p>
        }
        contact='Post your first job ad right now'
        button={{
          text: 'Try Now',
          href: selfServeButtonUrl,
        }}
      />
      <Card
        key='bulkPost'
        variant={CardVariant.FEATURED}
        name='BulkPost'
        shortDescription='Allows you to promote all of your open jobs on Lensa for one low monthly price'
        iconSrc='/employer/assets/images/svg/bulkpost_icon.svg'
        recommendedFor='Recommended for 10+ jobs'
        options={[
          { text: '40+ niche & general job board partners' },
          {
            text: 'Lensa handles all of your job postings',
          },
          { text: 'Redirecting job seekers back to your own careers page ' },
          { text: 'Fixed monthly fee that you can rely on ' },
        ]}
        paymentType='Monthly subscription'
        checkoutOptions={[CheckoutOptions.INVOICE, CheckoutOptions.CREDIT_CARD]}
        budgetType={
          <p>
            <b>Flat fee</b> based on job volume
          </p>
        }
        contact='Get a Quote today'
        button={{
          text: 'Get In Touch',
          href: bulkPostButtonUrl,
        }}
      />
      <Card
        key='performance'
        variant={CardVariant.DEFAULT}
        name='Performance'
        shortDescription='Allows you to advertise your jobs and controlling bids, budgets, and campaigns'
        iconSrc='/employer/assets/images/svg/performance_icon.svg'
        recommendedFor='Recommended for 500+ jobs'
        options={[
          { text: '40+ niche & general job board partners' },
          {
            text: 'Adjust job level bids',
            tooltip: 'Bids are accepted at the job level, can be set at a flat rate, or by Smartbid.',
          },
          {
            text: 'SmartBid technology',
            tooltip: 'SmarBid is a technology that sets base prices for jobs ads based on market trends.',
          },
          { text: 'Collect job postings from your careers page or job feed' },
        ]}
        paymentType='Pay per click'
        checkoutOptions={[CheckoutOptions.INVOICE, CheckoutOptions.CREDIT_CARD]}
        budgetType={
          <p>
            <b>Monthly budget</b>
          </p>
        }
        contact='Speak with a Specialist today'
        button={{
          text: 'Get In Touch',
          href: performanceButtonUrl,
        }}
      />
    </section>
  );
};

export default CardList;
