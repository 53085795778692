import { useCallback } from 'react';

const useScrollToSection = () => {
  const scrollToSection = useCallback(() => {
    if (!document) {
      return null;
    }

    const target = document.getElementById('hiringPlans');

    if (!target) {
      return null;
    }

    window.scrollTo({
      top: target.offsetTop,
      left: 0,
      behavior: 'smooth',
    });

    return null;
  }, []);

  return { scrollToSection };
};

export default useScrollToSection;
