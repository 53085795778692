import { AnalyticsEvent } from '../domain/AnalyticsEvent';
import AnalyticsService from '../domain/AnalyticsService';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export default class GTMAnalyticsService implements AnalyticsService {
  dataLayer: Record<string, any>;

  constructor() {
    this.dataLayer =
      typeof window !== 'undefined' &&
      typeof window.dataLayer !== 'undefined' &&
      window.dataLayer &&
      window.dataLayer.length
        ? window.dataLayer
        : [];
  }

  send = (event: AnalyticsEvent) => {
    try {
      this.dataLayer.push({ event: event.name, event_label: event.label, event_data: event.payload });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}
