import Button from '@/components/Button';

import { useUTMQueryParamsFromCurrentUrl, UrlBuilder } from 'modules/url';
import { useMemo } from 'react';

import css from './header.module.scss';

const Header = () => {
  const utmQueryParamsFromUrl = useUTMQueryParamsFromCurrentUrl();

  const signInUrl = useMemo(
    () =>
      new UrlBuilder()
        .setBaseUrl(process.env.NEXT_PUBLIC_DIRECTEMPLOYER_SITE_PATH || '')
        .setPath('/sign-in/')
        .setQueryParams(utmQueryParamsFromUrl)
        .setQueryParam({ name: 'utm_content', value: 'have+an+account+log+in' })
        .build(),
    [utmQueryParamsFromUrl]
  );

  return (
    <div className={css['wrap']}>
      <div className={css['innerWrap']}>
        <div className={css['logo']} />
        <div className={css['button--mobile']}>
          <a href={signInUrl} target='_blank'>
            <Button title='Have an account? Log in!' variant='headerM' />
          </a>
        </div>
        <div className={css['button--tabletup']}>
          <a href={signInUrl} target='_blank'>
            <Button title='Have an account? Log in!' variant='headerL' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
