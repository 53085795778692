import CardList from './components/card-list';
import css from './easy-to-manage.module.scss';

const EasyToManage: React.FC = () => (
  <section className={css['content']} id='hiringPlans'>
    <div className={css['header']}>
      <p>Flexible.</p>
      <div className={css['separator']} />
      <p>Easy to manage.</p>
    </div>
    <div className={css['background']} />
    <CardList />
  </section>
);

export default EasyToManage;
