export class AnalyticsEvent {
  name: string;

  label?: string;

  payload?: object | string;

  constructor(name: string, label?: string, payload?: object | string) {
    this.name = name;
    this.label = label;
    this.payload = payload;
  }
}

export class AnalyticsEventBuilder {
  name: string = '';

  label?: string;

  payload?: object | string;

  setName = (name: string) => {
    this.name = name;
    return this;
  };

  setLabel = (label: string) => {
    this.label = label;
    return this;
  };

  setPayload = (payload?: object | string) => {
    this.payload = payload;
    return this;
  };

  build = () => new AnalyticsEvent(this.name, this.label, this.payload);
}
