import classNames from 'classnames';
import { FC } from 'react';

import css from './tooltip.module.scss';

type TooltipProps = {
  text: string;
  className?: string;
};

const Tooltip: FC<TooltipProps> = ({ text, className = '' }) => (
  <div className={classNames(css['content'], className)}>
    <img src='/employer/assets/images/svg/info_icon.svg' alt='tooltip icon' /> <p className={css['text']}>{text}</p>
  </div>
);

export default Tooltip;
