import SubscriptionAPI from '../domain/SubscriptionAPI';
import { InvalidEmailFormatError } from '../domain/Errors';

export default class MockedEmployerCollectorAPI extends SubscriptionAPI {
  subscribe = async (email: string) => {
    if (!this.isValidEmailAddress(email)) {
      throw new InvalidEmailFormatError();
    }
  };
}
