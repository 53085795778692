import { icons } from '@/components/Button/icons';
import classNames from 'classnames';

import css from './button.module.scss';

type Variant = 'headerM' | 'headerL' | 'contentM' | 'contentL' | 'contentL-featured' | 'pricing' | 'pricing-featured';

export type { Variant as ButtonVariant };

type Icons = keyof typeof icons;

type ButtonProps = {
  title: string;
  variant: Variant;
  onClick?: () => void;
  icon?: Icons;
  className?: string;
};

const Button: React.FC<ButtonProps> = (props) => {
  const { title, variant, icon, className = '', onClick = () => null } = props;

  return (
    <div className={classNames(css[`wrap--${variant}`], className)} onClick={onClick}>
      {title}
      {!!icon && <div className={css['icon']}>{icons[icon]}</div>}
    </div>
  );
};

export default Button;
