import Hero from '@/components/hero';
import Header from '@/components/Header';
import Footer from '@/components/footer';
import ContactUs from '@/components/contact-us';
import WhoIsLensa from '@/components/who-is-lensa';
import PostingJobs from '@/components/posting-jobs';
import EasyToManage from '@/components/easy-to-manage';
import Communications from '@/components/communications';
import { SubscriptionForm } from 'modules/subscription-form';

const Home = () => (
  <>
    <Header />
    <Hero />
    <EasyToManage />
    <Communications />
    <PostingJobs />
    <SubscriptionForm />
    <WhoIsLensa />
    <ContactUs />
    <Footer />
  </>
);

export default Home;
