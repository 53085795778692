import Button from '@/components/Button';
import useScrollToSection from '../easy-to-manage/useScrollToSection';

import css from './hero.module.scss';

const Hero: React.FC = () => {
  const { scrollToSection } = useScrollToSection();

  return (
    <section className={css['content']}>
      <div className={css['wrap']}>
        <div className={css['grid-spacer']} />
        <div className={css['introduction']}>
          <h1>
            Find and Hire <br />
            <span>Talent</span> on <br className='mobile-only' />
            Auto-Pilot
          </h1>
          <div className={css['line']} />
          <p>
            Need talent fast? Instantly promote your open jobs to millions of active, engaged job seekers and advertise
            your jobs everywhere, all at once.
          </p>
          <div className={css['button']} onClick={scrollToSection}>
            <Button title='Find Out More' variant='contentM' />
          </div>
        </div>
        <div className={css['image-container']}>
          <img
            srcSet='/employer/assets/images/webp/fasttalent_hero_671w.webp 671w, /employer/assets/images/webp/fasttalent_hero_1055w.webp 1055w, /employer/assets/images/webp/fasttalent_hero_1610w.webp 1610w'
            sizes='
            (max-width: 671px) 100vw,
            (max-width: 1055px) 100vw,
            (max-width: 1610px) 60vw,
            100vw
            '
            src='/employer/assets/images/png/fasttalent_hero_1610w.webp'
            alt='Fasttalent hero'
            className={css['image']}
          />
        </div>
        <div className={css['grid-spacer']} />
      </div>
    </section>
  );
};

export default Hero;
