import { useCallback } from 'react';
import { QueryParam } from '../QueryParam';
import useQueryParamsFromCurrentUrl from './useQueryParamsFromCurrentUrl';

const useUTMQueryParamsFromCurrentUrl = () => {
  const utmFilter = useCallback(({ name }: QueryParam) => name.startsWith('utm_'), []);

  const utmQueryParamsFromUrl = useQueryParamsFromCurrentUrl(utmFilter);

  return utmQueryParamsFromUrl;
};

export default useUTMQueryParamsFromCurrentUrl;
