import css from './communications.module.scss';

const Communications: React.FC = () => (
  <section className={css['content']}>
    <div className={css['list']}>
      <div className={css['list-item']}>
        <img src='/employer/assets/images/svg/members_icon.svg' alt='Members icon' />
        <h3>
          <span>20M+</span> <br className='mobile-only' />
          <br className='tablet-only' />
          Lensa members
        </h3>
        <p>
          Promote your jobs to our <br className='tablet-only' />
          20M+ members <br className='mobile-only' />
          <br className='desktop-only' />
          via email, <br className='tablet-only' />
          SMS, and site search
        </p>
      </div>
      <div className={css['list-item']}>
        <img src='/employer/assets/images/svg/boards_icon.svg' alt='Boards icon' />
        <h3>
          <span>40+</span> <br className='mobile-only' />
          <br className='tablet-only' />
          job boards
        </h3>
        <p>
          Distribute your jobs to up <br className='tablet-only' />
          to 40+ partners <br className='mobile-only' />
          <br className='desktop-only' />
          by industry <br className='tablet-only' />
          and job type
        </p>
      </div>
      <div className={css['list-item']}>
        <img src='/employer/assets/images/svg/google_icon.svg' alt='Google icon' />
        <h3>
          <span>Google</span> <br className='mobile-only' />
          <br className='tablet-only' />
          for Jobs
        </h3>
        <p>
          Automatically publish <br />
          on Google for Jobs
        </p>
      </div>
    </div>
    <h4 className={css['description']}>
      Lensa will help you find talent <br className='mobile-only' />
      fast by exposing your jobs <br />
      in more places to help you get <br className='mobile-only' />
      more applications.
    </h4>
  </section>
);

export default Communications;
